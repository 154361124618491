<template>
  <div tft-end>
    <div class="wrap">
      <HomeHeader />
      <DiscordSection />
      <SpecialLeague />
      <CommunityLeague v-show="false" />
      <HistorySection />
    </div>
  </div>
</template>

<script>
import DiscordSection from '@/views/components/end/DiscordSection.vue';
import SpecialLeague from '@/views/components/end/SpecialLeague.vue';
import CommunityLeague from '@/views/components/end/CommunityLeague.vue';
import HistorySection from '@/views/components/end/HistorySection.vue';
import HomeHeader from '@/views/components/gameHome/HomeHeader.vue';
import meta from '@/data/meta';

export default {
  name: 'TftEnd',
  components: { SpecialLeague, DiscordSection, CommunityLeague, HistorySection, HomeHeader },
  metaInfo() {
    return meta[this.$route.params.id][this.$route.name];
  },
};

</script>

<style lang="less">
@import '~@/less/proj.less';

[tft-end] { .font-g; .mh-c;
  [discord-section] {
    .contents-wrapper {
      .detail {
        .main {
          > em { .c(#9ed40d); }
        }
      }
      .discord {
        > a { .bgc(#9ed40d);}
      }
    }
  }
  [history-section] {
    .contents-wrapper {
      .contents-holder {
        .box {
          .detail {
            h3 { .c(#2e83eb);}
          }
        }
      }
    }
  }
}

</style>
